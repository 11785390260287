.calendar {

  .top {
    display: flex;
    padding: 16px 0;

    .month-name {
      text-align: center;
      width: 100%;
      font-size: 20px;
      font-weight: 700;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      font-size: 20px;
      font-weight: 700;
      padding: 0;
      color: rgb(9, 114, 211);
    }
  }

  .content {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 0;
  }

  .day-of-week {
    text-align: center;
    padding-bottom: 8px;
    font-size: 14px;
    text-transform: uppercase;
    color: rgb(141, 153, 168);
  }

  .day {
    border: 1px solid rgb(182, 190, 201);
    border-right: none;
    border-top: none;
    text-align: right;

    .number {
      padding-top: 12px;
      padding-right: 16px;
    }

    .content {
      display: block;
      text-align: center;
      padding-left: 4px;
      padding-right: 4px;
      padding-top: calc(50% - 20px);
      padding-bottom: calc(50% - 10px);

      a.cancelled {
        text-decoration: line-through !important;
      }

      & .reason {
        font-size: 12px;
        color: rgb(141, 153, 168);
      }
    }

    &.first-day-first-week {
      border-top-left-radius: 16px;
    }

    &.last-day-first-week {
      border-top-right-radius: 16px;
    }

    &.first-day-last-week {
      border-bottom-left-radius: 16px;
    }

    &.last-day-last-week {
      border-right: 1px solid rgb(182, 190, 201);
      border-bottom-right-radius: 16px;
    }

    &:nth-child(7n) {
      border-right: 1px solid rgb(182, 190, 201);
    }

    &:nth-child(n+8):nth-child(-n+14) {
      border-top: 1px solid rgb(182, 190, 201);
    }

    &.prev-month {
      border-top: none !important;
      border-left: none;
    }

    &.next-month:first-child {
      border-left: 1px solid rgb(182, 190, 201);
    }

    &.next-month {
      border-bottom: none;
      border-left: none;
      border-right: none;
    }
  }
}