.attendance-toggle {
  text-align: center;

  button {
    border: none;
    background-color: transparent;

    span.true {
      font-size: 96px;
      font-weight: 900;
      color: green;
    }

    span.false {
      font-size: 96px;
      font-weight: 900;
      color: red;
    }

    span.null {
      font-size: 90px;
      padding: 3px;
      font-weight: 900;
      color: grey;
    }
  }
}