@import "./common/constants.scss";

body {
  margin: 0;
  padding: 0;
  font-family:
    'Arial Nova',
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#firebaseui-auth-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -36px;
  margin-left: -118px;
}


.global-spinner {
  position: absolute !important;
  right: 20px;
  bottom: 20px;
}

img.profile {
  border: 1px solid #ccc;
  width: 150px;
  height: 150px;
}

.rmwc-avatar {
  border: 2px solid #ccc;
}

a[class^="awsui_identity"] {
   span[class^="awsui_title"] {
    &:after {
      content: "by Asemio";
      font-size: 14px;
      font-weight: 400;
      margin-left: 6px;
    }
  }
}

a[class^="awsui_header-link"] {
  display: block !important;
}

span[class^="awsui_header-link-text"] {
  display: block;
  margin-top: 16px;
  font-weight: 700 !important;
}

.green-background {
  background-color: #025022;
}

.white {
  color: #fff !important;
}

.check-eligibility {
  padding-top: 0 !important;

  .result {
    padding: 20px 0;
    color: #000;
    font-size: 16px;
    font-weight: normal;

    a {
      // color: #00deff;
      font-family: 'Open Sans';
      font-weight: bolder;
      text-decoration: none;
    }
  }
}

.normal-case {
  text-transform: none !important;
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.required {
  &::after {
    content: " *";
    color: red;
    font-weight: 900;
  }
}

td {
  vertical-align: top;
}

.highlight {
  background-color: yellow;
}

span[class^="awsui_placeholder"] {
  padding-right: 2px;
}

img[class^="awsui_header-logo"] {
  max-inline-size: 100% !important;
}

.profile {
  color: #ccc;
  border: 2px solid #ccc;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

div.un-containerized {
  div[class*="awsui_header-variant-cards"] {
    border: none !important;
    padding: 0 0 0 4px !important;
  }
}

.heading-m {
  font-size: 18px;
}

.font-weight-700 {
  font-weight: 700 !important;
}

button.transparent {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  color: rgb(9, 114, 211);
}

.red-link {
  color: #d91515 !important;
}

a {
  text-decoration: none !important;
}

a:hover {
  text-decoration: underline !important;
  color: #0972d3 !important;
}